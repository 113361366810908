@import "variables";
@import 'icone-garanteasy';
@import "src/main/webapp/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

/* GARANTEASY */
html {
  position: relative;
  min-height: 100%;
}

body {
  //background: #fafafa;
  //    font-family: 'Roboto', sans-serif;
  //color: $garanteasy-black;
  //    font-size: 12px;
  //background-color: $garanteasy-green;
  margin-bottom: 60px;
  /* Margin bottom by footer height */

}

span.btn.btn-default.form-control.ui-select-toggle {
  background-color: white;
  border: 1px solid #ccc !important;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;

  a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    //font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
  }
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: $garanteasy-black;
  padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand {
  &.logo {
    padding: 5px 15px;

    .logo-img {
      display: inline-block;

      /* Custom, iPhone Retina */
      @media only screen and (min-width: 1px) {
        height: 40px;
        min-width: 40px;
      }

      /* Extra Small Devices, Phones */
      @media only screen and (min-width: 480px) {
        height: 30px;
        min-width: 30px;
      }

      /* Small Devices, Tablets */
      @media only screen and (min-width: 768px) {
        height: 45px;
        min-width: 45px;
      }

      /* Medium Devices, Desktops */
      @media only screen and (min-width: 992px) {
        height: 45px;
      }

      /* Large Devices, Wide Screens */
      @media only screen and (min-width: 1200px) {
        height: 45px;
      }
    }
  }
}

nav {
  .fa-bars {
    height: 36px;
    background-color: transparent;
    //color: white;
  }
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;

  h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
  }
}


/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.voffset {
  margin-top: 2px;
}

.voffset1 {
  margin-top: 5px;
}

.voffset2 {
  margin-top: 10px;
}

.voffset3 {
  margin-top: 15px;
}

.voffset4 {
  margin-top: 30px;
}

.voffset5 {
  margin-top: 40px;
}

.voffset6 {
  margin-top: 60px;
}

.voffset7 {
  margin-top: 80px;
}

.voffset8 {
  margin-top: 100px;
}

.voffset9 {
  margin-top: 150px;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;

  &:last {
    margin: 0 !important;
  }
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  position: fixed;
  top: 100px;
  right: 2%;
  z-index: 99999;

  .alert {
    text-overflow: ellipsis;

    div {
      display: inline-block;
    }

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0px;
      margin: 0px;
    }

    .popover pre {
      font-size: 10px;
    }

    padding-right: 30px;
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side =="all" {
    .no-padding {
      padding: 0 !important;
    }
  }

  @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.jh-table> {
  tbody>tr>td {
    /* Align text in td verifically (top aligned by Bootstrap) */
    vertical-align: middle;
  }

  thead>tr>th> {

    .glyphicon-sort,
    .glyphicon-sort-by-attributes,
    .glyphicon-sort-by-attributes-alt {
      /* less visible sorting icons */
      opacity: 0.5;

      &:hover {
        /* full visible sorting icons and pointer when mouse is over them */
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details> {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details> {
    dt {
      margin-bottom: 15px;
      white-space: normal;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.datetime-picker-dropdown>li.date-picker-menu div>table .btn-default,
.uib-datepicker-popup>li>div.uib-datepicker>table .btn-default {
  border: 0;
}

.datetime-picker-dropdown>li.date-picker-menu div>table:focus,
.uib-datepicker-popup>li>div.uib-datepicker>table:focus {
  outline: none;
}

/* ==========================================================================
Social button
========================================================================== */
.jh-btn-social {
  margin-bottom: 5px;
  display: none;
}

.jh-btn-google {
  background-color: #dd4b39;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.jh-btn-google:hover,
.jh-btn-google:focus,
.jh-btn-google:active,
.jh-btn-google.active,
.open>.dropdown-toggle.jh-btn-google {
  background-color: #c23321;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.jh-btn-facebook {
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.jh-btn-facebook:hover,
.jh-btn-facebook:focus,
.jh-btn-facebook:active,
.jh-btn-facebook.active,
.open>.dropdown-toggle.jh-btn-facebook {
  background-color: #2d4373;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.jh-btn-twitter {
  background-color: #55acee;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.jh-btn-twitter:hover,
.jh-btn-twitter:focus,
.jh-btn-twitter:active,
.jh-btn-twitter.active,
.open>.dropdown-toggle.jh-btn-twitter {
  background-color: #2795e9;
  border-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

jh-social {
  display: none;

  &[ng-provider="facebook"] {
    display: inherit;
  }

  &[ng-provider="google"] {
    display: inherit;
  }
}

.register {
  .merchant-logo {
    float: right;
    margin: 0px 0px 15px 15px;
  }
}

#home {
  .btn {
    text-align: left;
    padding-left: 15px;
    margin-bottom: 5px;
  }
}

.legalese {

  h2,
  p,
  li {
    font-size: smaller;
  }

  h1 {
    font-size: small;
  }
}

// style.css

.ul-no-bump {
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: none;
}

.page-header {
  padding: 10px 20px 0px 20px;
  margin: 0;
  height: auto;
  background-color: white;
  margin-bottom: 1em;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.sidebar-block {
  float: left;
  width: 100%;
  margin-bottom: 5px;
}

.sidebar-menu {
  background-color: $garanteasy-green;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
  box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
}

.sidebar-menu.sidebar-block {
  h5 {
    color: white;
    font-size: large;

    .fas.pull-right {
      margin-right: 10px;
    }
  }

  // li {
  //   font-size: 12px;
  // }
}

.sidebar-suggestion {
  text-transform: uppercase;
}

.sidebar-menu .block-title {
  color: #fff;
}

.block-title {
  margin: 20px 0 15px 0;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin-left: 15px;
}

.side-menu {
  margin: 0 0 1px 0;

  li {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #777777;

    a {
      color: #fff;
      font-size: 10px;
      display: inline-block;
      width: 100%;
      line-height: 30px;
      padding-left: 15px;
      padding-right: 22px;
    }
  }
}

.side-menu li a:hover,
.side-menu li.active a {
  color: $garanteasy-green-text;
  background-color: #fff;
  text-decoration: none;
  background-image: url(../../content/images/arrow-right-green.png);
  background-position: right center;
  background-repeat: no-repeat;
}

.side-menu {
  li {
    a.isDisabled {
      color: currentColor;
      text-decoration: none;
    }
  }
}

.sidebar-product {
  margin-bottom: 25px;
  float: left;
  width: 100%;
}

.sidebar-product img {
  max-width: 100%;
  height: auto;
  border: 1px solid #dedede;
}

.sidebar-product .col-sm-4 {
  padding: 0;
  text-align: center;
}

.sidebar-product .col-sm-8 {
  padding-right: 0;
}

.sidebar-product .title {
  color: $garanteasy-black;
  font-size: 18px;
  line-height: 22px;
}

.sidebar-product .btn-default {
  //font-size: 12px;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  height: 26px;
  line-height: 28px;
  border: 0;
  margin-top: 10px;
  position: relative;
}

.sidebar-product .btn-default span {
  background-image: url(../../content/images/arrow-right.png);
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 22px;
  height: 26px;
  position: absolute;
  left: 4px;
}

.btn-default-green {
  background: $garanteasy-green;
  color: #fff;
}

.btn-default.btn-default-orange:hover {
  background: rgba(246, 121, 54, 1);
  color: #fff;
}

.btn-default-orange {
  background: rgba(246, 121, 54, 1);
  background: -moz-linear-gradient(top, rgba(246, 121, 54, 1) 0%, rgba(197, 97, 43, 1) 100%);
  background: -webkit-gradient(color-stop(0%, rgba(246, 121, 54, 1)), color-stop(100%, rgba(197, 97, 43, 1)));
  background: -webkit-linear-gradient(top, rgba(246, 121, 54, 1) 0%, rgba(197, 97, 43, 1) 100%);
  background: -o-linear-gradient(top, rgba(246, 121, 54, 1) 0%, rgba(197, 97, 43, 1) 100%);
  background: -ms-linear-gradient(top, rgba(246, 121, 54, 1) 0%, rgba(197, 97, 43, 1) 100%);
  background: linear-gradient(to bottom, rgba(246, 121, 54, 1) 0%, rgba(197, 97, 43, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f67936', endColorstr='#c5612b', GradientType=0);
  color: #fff;
}

.btn-default-grey {
  background: rgba(180, 180, 180, 1);
  background: -moz-linear-gradient(top, rgba(180, 180, 180, 1) 0%, rgba(155, 155, 155, 1) 100%);
  background: -webkit-gradient(left top,
      left bottom,
      color-stop(0%, rgba(180, 180, 180, 1)),
      color-stop(100%, rgba(155, 155, 155, 1)));
  background: -webkit-linear-gradient(top, rgba(180, 180, 180, 1) 0%, rgba(155, 155, 155, 1) 100%);
  background: -o-linear-gradient(top, rgba(180, 180, 180, 1) 0%, rgba(155, 155, 155, 1) 100%);
  background: -ms-linear-gradient(top, rgba(180, 180, 180, 1) 0%, rgba(155, 155, 155, 1) 100%);
  background: linear-gradient(to bottom, rgba(180, 180, 180, 1) 0%, rgba(155, 155, 155, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b4b4b4', endColorstr='#9b9b9b', GradientType=0);
  color: #fff;
}

.btn-default {
  border: 0 !important;
}

.btn-default.btn-default-grey:hover {
  background: rgba(180, 180, 180, 1);
  color: #fff;
}

.sidebar-garanzie {
  margin-bottom: 25px;
}

.sidebar-garanzie h4.block-title {
  margin-top: 0;
}

// TODO: inutile?
.garanzie-wrapper {
  width: 100%;
  // float: left;
  border-bottom: 1px solid #c9c9c9;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 15px;
}

// TODO: inutile?
.garanzie-wrapper .g-value {
  background-color: #fff;
  display: inline-block;
  height: 29px;
  line-height: 30px;
  // float: left;
  width: 36px;
  text-align: center;
  color: $garanteasy-black;
  font-weight: 700;
}

.garanzie-wrapper .g-title {
  padding-left: 10px;
}

.garanzie-wrapper.color-green {
  background-color: #39b54a;
}

.garanzie-wrapper.color-orange {
  background-color: #f8941d;
}

.garanzie-wrapper.color-orange-dark {
  background-color: #f26522;
}

.garanzie-wrapper.color-grey {
  background-color: #8c7f75;
}

.garanzie-holder {
  -webkit-box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
  box-shadow: 0px 0px 10px 3px rgba(215, 215, 215, 1);
  border: 1px solid #c9c9c9;
  border-bottom: 0;
  float: left;
  width: 100%;
}

.sp-icon-check {
  background-image: url("../../content/images/icon-check.png");
  background-position: right + 5px center;
  background-repeat: no-repeat;
}

.sp-icon-exclamation {
  background-image: url("../../content/images/icon-exclamation.png");
  background-position: right + 5px center;
  background-repeat: no-repeat;
}

.sp-icon-ban {
  background-image: url("../../content/images/icon-ban.png");
  background-position: right + 5px center;
  background-repeat: no-repeat;
}

.sp-icon-question {
  background-image: url("../../content/images/icon-question.png");
  background-position: right + 5px center;
  background-repeat: no-repeat;
}

.sidebar-promo img {
  max-width: 100%;
  height: auto;
}

/**
*   MAIN PAGE
**/
.main-title {
  color: $garanteasy-green;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 40px;
  margin: 13px 0 40px 0;

  @media screen and (max-width: 425px) {
    font-size: 22px;
    margin: 13px 0 10px 0;
  }

  word-wrap: break-word;
}

.registration-title {
  color: $garanteasy-green;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 40px;
  margin: 13px 0 40px 15%;

  @media screen and (max-width: 991px) {
    font-size: 22px;
    margin: 13px 0 10px 0;
  }

  word-wrap: break-word;
}

.gallery img {
  max-width: 100%;
  height: auto;
}

.main-image {
  margin-bottom: 7px;
}

.main-image>a {
  position: relative;
}

.main-image:hover .image-button {
  display: block;
}

.image-button {
  /*display: none;*/
  position: absolute;
  top: 50%;
  width: 65px;
  height: 65px;
  margin-top: -32.5px;
  display: block;
}

.image-button:hover {
  cursor: pointer;
}

.image-download {
  background: url("../../content/images/download.png") center no-repeat;
  /*right: 40px;*/
}

.image-zoom {
  background: url("../../content/images/zoom.png") center no-repeat;
  left: 40px;
}

.images-list {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}

.images-list li {
  float: left;
  margin: 0 6px 6px 0;
}

.barcode {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.barcode img {
  max-width: 100%;
  height: auto;
  border: 1px solid #acacac;
}

.barcode-smaller {
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;
  color: $garanteasy-black;
  float: left;
  width: 100%;
}

.barcode-large {
  font-size: 20px;
  line-height: 24px;
  color: $garanteasy-black;
  float: left;
  width: 100%;
}

.button-wrapper {
  float: left;
  width: 100%;
}

.button-wrapper .btn-default {
  border: 0;
  width: 100%;
  margin-bottom: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  font-size: 14px;
  /*height: 44px;*/
  white-space: normal;
  line-height: 33px;
}

.bottoniera {
  padding-top: 15px;
  text-align: right;
}

.thumbnail-container {
  min-width: 250px;

  .thumbnail {
    position: relative;
    background-color: white;

    .bottoniera {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 5px;
      padding-right: 5px;

      button {
        margin-left: 3px;
      }

      .fas {
        margin-right: 0px;
      }
    }

    .fas {
      margin-right: 5px;
    }

    .thumbnail-row {
      display: flex;
      text-align: left;
      margin-left: -20px;

      .thumbnail-icon {
        display: inline-block;
        align-self: center;
      }

      .thumbnail-label {
        display: inline-block;
      }
    }
  }
}

.product-title {
  font-size: 32px;
  line-height: 36px;
  color: $garanteasy-black;
  margin-top: 8px;
  margin-bottom: 22px;
}

.detail-h {
  font-size: 18px;
  color: $garanteasy-black;
  //line-height: 50px;
  height: 50px;
  display: inline-block;
  padding: 0 7px;
}

.sp-text .detail-h {
  width: 30%;
  float: left;
  height: auto;
}

.detail-d {
  border-left: 1px solid #dbdbdb;
  font-size: 18px;
  color: $garanteasy-black;
  line-height: 50px;
  height: 50px;
  display: inline-block;
  padding: 0 7px;
}

.detail-f {
  border-left: 1px solid #dbdbdb;
  font-size: 18px;
  color: $garanteasy-black;
  /*    line-height: 50px;
        height: 50px;*/
  display: inline-block;
  padding: 0 7px;
}

.sp-text .detail-d {
  width: 70%;
  float: left;
  height: auto;
  font-size: 10px;
  line-height: 18px;
  padding: 16px 8px;
}

.detail-text {
  height: 73px;
  overflow: hidden;
}

.product-details {
  li {
    border: 1px solid #dbdbdb;
    border-bottom-width: 0;
    width: 100%;
    /*float: left;*/
  }

  .show-more {
    color: $garanteasy-green;
    float: right;
  }

  .show-more:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  li:last-child {
    border-bottom-width: 1px;
  }

  margin-bottom: 50px;
  float: left;
  width: 100%;
}

.product-garanzia-wrapper {
  float: left;
  width: 100%;
}

.product-garanzia-wrapper h5 {
  margin: 0 0 25px 0;
  color: $garanteasy-black;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 22px;
}

.product-garanzia {
  width: 100%;
  margin-bottom: 30px;
}

.product-garanzia td {
  padding: 4px 6px;
  font-size: 15px;
  color: #000000;
}

.product-garanzia td span {
  width: 100%;
  display: inline-block;
  padding-right: 22px;
}

.product-garanzia td.bb {
  border-bottom: 1px solid #dbdbdb;
}

.color-gy {
  background: url("../../content/images/gy.png") right center no-repeat;
}

.color-gg {
  background: url("../../content/images/gy.png") right center no-repeat;
}

.color-gr {
  background: url("../../content/images/gr.png") right center no-repeat;
}

.color-gv {
  background: url("../../content/images/gv.png") right center no-repeat;
}

.btn-default.btn-stampa,
.btn-default.btn-elimina {
  //font-size: 12px;
  font-weight: 700;
  /*padding-left: 30px;*/
  padding-top: 0;
  padding-bottom: 0;
  text-transform: uppercase;
  height: 26px;
  line-height: 28px;
  border: 0;
  position: relative;
}

.btn-stampa.btn-default span {
  background-image: url(../../content/images/icon-print.png);
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 22px;
  height: 26px;
  position: absolute;
  left: 4px;
}

.btn-elimina.btn-default span {
  background-image: url(../../content/images/icon-delete.png);
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 22px;
  height: 26px;
  position: absolute;
  left: 4px;
}

.footer {
  margin-top: 25px;
  text-align: center;
  background-color: $garanteasy-green;
  //padding: 50px 0 10px 0;
  float: left;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 60px;
  /* Set the fixed height of the footer here */
  display: table;

  .footer-text {
    display: table-cell;
    vertical-align: middle;
    color: white;
    //font-size: 12px;
    //line-height: 16px;
  }
}

.navBar-links {

  /* Custom, iPhone Retina */
  @media only screen and (min-width: 320px) {
    color: black;
    background-color: white;
  }

  /* Extra Small Devices, Phones */
  @media only screen and (min-width: 480px) {
    color: black;
    background-color: white;
  }

  /* Small Devices, Tablets */
  @media only screen and (min-width: 768px) {
    color: white;
    background-color: $garanteasy-green;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-width: 992px) {
    color: white;
    background-color: $garanteasy-green;
  }

  /* Large Devices, Wide Screens */
  @media only screen and (min-width: 1200px) {
    color: white;
    background-color: $garanteasy-green;
  }
}

.btn-custom {
  width: 210px !important;
}

.th-green {
  color: $garanteasy-green;
}

.td-gray {
  background-color: #f5f5f5;
  border: 1px solid #dddddd;
}

.link-green {
  color: $garanteasy-green;
}

.button-delete {
  background-color: #a5a5a5;
  color: white;
  margin: 3px;
  border: none;
}

.button-search {
  background-color: $garanteasy-green;
  color: white;
  margin: 3px;
  border: none;
}

.action-list {
  padding: 3px;
  vertical-align: middle;
}

.navbar-fixed {
  position: fixed;
  z-index: 1030;
}

.content-margin {
  margin-top: 75px;
}

.hr-green {
  background-color: $garanteasy-green;
  color: $garanteasy-green;
  width: 100%;
  height: 1px;
}

.hr-sub-green {
  background-color: $garanteasy-green;
  color: $garanteasy-green;
  width: 100%;
  height: 2px;
}

.i-sidebar,
.sidebar-suggestion {
  background-color: $garanteasy-blue;
  color: white;
  font-size: x-large;
  margin-top: 2px;
}

.sidebarVerticalScroll {
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.rdetail-font-size-colored {
  font-size: 14px;
  // color: $garanteasy-green;
  font-weight: bold;
}

.form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.form-control-modified {
  @extend .form-control;
  display: block;
  width: 100%;
  //height: 26px;
  padding: 6px 12px;
  font-size: 14px;
  //line-height: 1.42857143;
  // color: $garanteasy-gray;
  // background-color: white;
  // background-image: none;
  // border: 1px solid $garanteasy-gray;
  // border-radius: 4px;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  // -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  // transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}



select.form-control-modified {
  padding-top: unset;
  padding-bottom: unset;
}

.row-margin {
  margin-bottom: 3px;
}

.nopadding {
  padding: 0;
}

.margin-content-rdetail {
  margin-left: -30px;
  margin-right: -15px;
}

//#61/Barra di caricamento Slide 6
#loading-bar .bar {
  height: 8px !important;
  margin-bottom: 8px !important;
}

//.navbar-fixed-top {
//margin-top: 8px !important;
//}

.btn-info {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;

  .fa-info-circle {
    font-size: 21px;
  }
}

/* use @mixins to define buttons */
// .btn-dark {
//   @include button-variant(white, $garanteasy-green, $garanteasy-green);
//   a {
//     color: white;
//   }
// }

@media(max-width:767px) {
  .title-page-mobile {
    .btn-dark {
      background-color: transparent;
      border-color: transparent;
    }
  }


}

.btn-default[disabled] {
  background-color: $btn-default-disabled-color;
}

// .btn-light {
//   background-color: white;
// }

.isDisabled {
  color: gray;
  cursor: not-allowed;
  opacity: 0.5;
}

.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.fa-exclamation-triangle {
  //font-size: large;
  vertical-align: middle;
}

.croppie-container .cr-boundary {
  margin: 0;
}

.search-form-reset {
  font-size: 1.5rem;
}

/* ==========================================================================
Navigation and Mobile Improvments
========================================================================== */
.navigation-button {
  margin: 0;
  font-size: 2.5rem;
  padding: 1rem 0;
}

#sidebar {
  /* for the animation */
  transition: margin 0.3s ease;
  width: 60%;
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 75px;
  background-color: $garanteasy-green;
  overflow-y: scroll;
  overflow-x: hidden;
  bottom: 0;

  &.collapsed {
    display: block;
    /* same width as sidebar */
    margin-right: -60%;
  }

  @media screen and (max-width: 374px) {
    width: 65%;

    &.collapsed {
      margin-right: -65%;
    }
  }

  ul {
    background-color: $garanteasy-green;

    li {
      background-color: $garanteasy-green;

      a {
        background-color: $garanteasy-green;
        color: white;
        //font-size: 12px;
        font-weight: 600;

        .fas {
          margin: 0 5px;
        }
      }

      &.divider {
        background-color: #e5e5e5;
      }
    }

    li.color-action {
      a {
        background-color: $garanteasy-green;
      }
    }

    li.color-view {
      a {
        background-color: $garanteasy-gray;
      }
    }

    li.color-info {
      a {
        background-color: $garanteasy-blue;
      }
    }
  }
}

#not-collapse {
  .navbar-collapse.collapse {
    display: block !important;
  }

  .navbar-nav>li,
  .navbar-nav {
    float: left !important;
  }

  .navbar-nav.navbar-right:last-child {
    margin-right: -15px !important;
  }

  .navbar-right {
    float: right !important;
  }

  .navBar-links {
    color: $garanteasy-black;
    background-color: white;
  }

  .dropdown-menu {
    @media screen and (max-width: 767px) {
      right: 0;
      left: auto;
      position: absolute;
      top: 100%;
      z-index: 99999;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      list-style: none;
      list-style-type: none;
      list-style-position: initial;
      list-style-image: initial;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    @media screen and (max-width: 375px) {
      left: -60px;
    }
  }
}

.navigation-menu {
  @media screen and (max-width: 355px) {
    padding-left: 0;
    padding-right: 5px;

    .navbar-brand {
      padding: 0;
    }
  }
}

.ui-select-toggle {
  color: $garanteasy-black;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.ui-select-match-text {
  color: $garanteasy-black;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}

.ui-select-match {
  span {
    &.ui-select-toggle {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.ui-select-toggle {
  >.btn.btn-link {
    margin-right: 10px;
    top: 6px;
    position: absolute;
    right: 10px;
  }
}

.bottoniera {
  @media screen and (max-width: 359px) {
    padding: 0;
  }
}

#mobile-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background-color: $garanteasy-green;
  height: 33px;

  .footer-icon {
    margin: 10px 20px;
    font-size: 3rem;
    color: white;
    cursor: pointer;
  }

  .searchBar {
    float: right;
    padding: 0;
    padding-left: 10px;
  }

  .glyphicon {
    font-size: 2rem;
  }
}

#freshwidget-button {
  @media screen and (max-width: 767px) {
    margin-left: calc((100vw - 50px));
  }
}

.input-group {
  .input-group-addon {
    //font-size: 12px;
    top: auto;
  }

  // .fas {
  //   margin-top: 3px;
  // }
}

input.ng-invalid {
  color: #a94442;
}

a.disabled {
  color: #777777;
  border-color: #ddd;
  cursor: not-allowed;
}

.page {
  .sidebar-elenco {
    .sidebar-menu {
      background-color: white;

      h5 {
        color: $garanteasy-black;
      }

      ul {
        li {
          a {
            color: $garanteasy-black;
          }
        }
      }
    }
  }
}

.tooltip-inner {
  background-color: $garanteasy-blue;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $garanteasy-blue;
}

.select-group {
  .fas {
    margin-right: 1rem;
  }
}

.title-page-mobile {
  width: 100vw;
  background-color: $garanteasy-gray;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  padding: 5px;
  //border-bottom: 2px solid $garanteasy-green;
  margin-bottom: 0.6rem;
  margin-top: 1em;

  .col-xs-8 {
    padding-top: 16px;

    @media screen and (min-width: 370px) {
      padding-top: 20px;

      strong {
        span {
          font-size: 1.7rem !important;
        }
      }
    }

    @media screen and (min-width: 425px) {
      padding-top: 20px;

      strong {
        span {
          font-size: 2rem !important;
        }
      }
    }

    @media screen and (min-width: 510px) {
      padding-top: 25px;

      strong {
        span {
          font-size: 2.5rem !important;
        }
      }
    }

    @media screen and (min-width: 625px) {
      padding-top: 35px;

      strong {
        span {
          font-size: 2.8rem !important;
        }
      }
    }
  }

  .col-xs-2 {
    padding: 0;

    .button-row {
      width: 100%;
      font-size: 1rem;

      .btn {
        padding: 0;

        .fas {
          font-size: 1.8rem;

          @media screen and (min-width: 370px) {
            font-size: 2rem;
          }

          @media screen and (min-width: 425px) {
            font-size: 2.2rem;
          }

          @media screen and (min-width: 510px) {
            font-size: 2.8rem;
          }

          @media screen and (min-width: 625px) {
            font-size: 3.2rem;
          }
        }

        .fa-info-circle {
          color: #fff;
        }
      }

      &.second-row {
        padding-top: 0.5rem;

        @media screen and (min-width: 370px) {
          padding-top: 1rem;
        }

        @media screen and (min-width: 425px) {
          padding-top: 1.2rem;
        }

        @media screen and (min-width: 510px) {
          padding-top: 1.7rem;
        }

        @media screen and (min-width: 625px) {
          padding-top: 2.2rem;
        }
      }

      &.single-row {
        padding-top: 1.2rem;

        @media screen and (min-width: 370px) {
          padding-top: 1.5rem;
        }

        @media screen and (min-width: 425px) {
          padding-top: 1.7rem;
        }

        @media screen and (min-width: 510px) {
          padding-top: 2.2rem;
        }

        @media screen and (min-width: 625px) {
          padding-top: 2.5rem;
        }
      }
    }
  }
}

.fas {
  font-size: large;
}

.fa-9x {
  font-size: 9em !important;
}

:not(button)>span.fas,
:not(button)>i.fas,
:not(button)>span.fa,
:not(button)>i.fa,
:not(button)>span.glyphicon {
  color: $garanteasy-green;
}

.btn-group {
  .fas {
    font-size: unset;
  }
}

.img-shop-receipt {
  -webkit-filter: grayscale(100%) contrast(200%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) contrast(200%);
  margin-left: auto;
  margin-right: auto;
}

.rdetail-font-size-black {
  font-size: 14px;
  color: $garanteasy-black;
}

.dashboard-row-margin {
  margin-bottom: 5px;
}

.dashboard-inline-margin {
  margin-right: 5px;
}

.warning-color {
  color: #FAC05E;
}

.dashboard-icon {
  max-height: 70px;
  margin-top: 4px;
}

.dashboard-icon-sidebar {
  max-height: 30px;
  margin-top: 4px;
}

.dashboard-icon-info {
  height: 110px;
  max-width: 140px;
  width: 100%;
  display: inline-flex;

  i {
    font-size: 95px;
    margin: auto;
    color: $garanteasy-blue;
  }

  @media (max-width: 1024px) {
    height: 80px;
    max-width: 100px;
    width: 100%;

    i {
      font-size: 55px;
    }
  }
}

.dashboard-icon-mobile {
  font-size: 35px;
  margin-right: 10px;

  @media (max-width: 767px) {
    display: inline !important;
  }
}

.dashboard-icon-button-mobile {
  border-radius: 2px;
  pointer-events: none;

  @media (max-width: 767px) {
    display: inline !important;
  }
}

.dashboard-icon-info-mobile {
  height: 40px;
  max-width: 50px;
  width: 100%;

  @media (max-width: 767px) {
    display: inline !important;
  }

  i {
    font-size: 35px;
    margin: auto;
    color: $garanteasy-blue;
  }
}

.dashboard-img-mobile {
  max-height: 50px;

  @media (max-width: 767px) {
    display: inline !important;
  }
}

.no-margin {
  margin: 0;
}

.fa-file-pdf,
.fa-file-word,
.fa-file-archive,
.fa-file-excel {
  font-size: 50px;
}

.smile {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/226-smile.png);
}

.smile2 {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/227-smile2.png);
}

.angry {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/238-angry.png);
}

.angry2 {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/239-angry2.png);
}

.sad {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/230-sad.png);
}

.sad2 {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/231-sad2.png);
}

.cool {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/236-cool.png);
}

.cool2 {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/237-cool2.png);
}

.neutral {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/248-neutral.png);
}

.neutral2 {
  height: 18px;
  width: 18px;
  background-size: contain;
  background-image: url(../../content/images/249-neutral2.png);
}

.fast-track-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background-color: $garanteasy-green;
  height: 50px;

  .footer-icon {
    margin: 10px 20px;
    font-size: 3rem;
    color: white;
    cursor: pointer;
  }

  .searchBar {
    float: right;
    padding: 0;
    padding-left: 10px;
  }

  .glyphicon {
    font-size: 2rem;
  }

  .btn {
    border: 1px solid $garanteasy-black;
    font-size: 2rem;
    font-weight: 600;
    background-color: lighten($garanteasy-green, 3%);
    margin-top: 4px;
  }

  .fas {
    font-size: larger;
  }
}

.display-col-mobile {
  @media (max-width: 767px) {
    display: block !important;
  }
}

/* REFACTORING UI */
.ge-background-white {
  background-color: white
}

.ge-divider {
  border-right: 5px solid #16a74a;
}

@media (max-width: 1023px) {
  .ge-divider {
    border-right: 0px solid transparent;
    border-bottom: 5px solid #16a74a;
  }
}

.btn-default.dark {
  background: #646464;
}

.navbar-fixed-bottom a {
  color: white;
}

.ge-sidebar .list-group-item {
  border: none;
}

.ge-sidebar .list-group-item {
  background-color: transparent;
}

.ge-sidebar .list-group-item.disabled,
.ge-sidebar .list-group-item.disabled:hover,
.ge-sidebar .list-group-item.disabled:focus {
  color: #e2e0e0;
}

.ge-sidebar .list-group-item.disabled {
  display: none;
}

#bottomNav .disabled {
  display: none;
}

/* CARD css */

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  margin-bottom: 1em;
}

.card .card-heading {
  padding: 0 20px;
  margin: 0;
}

.card .card-heading.simple {
  font-size: 20px;
  font-weight: 300;
  color: #777;
  border-bottom: 1px solid #e5e5e5;
}

.card .card-heading.image img {
  display: inline-block;
  width: 46px;
  height: 46px;
  margin-right: 15px;
  vertical-align: top;
  border: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.card .card-heading.image .card-heading-header {
  display: inline-block;
  vertical-align: top;
}

.card .card-heading.image .card-heading-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
}

.card .card-heading.image .card-heading-header span {
  font-size: 12px;
  color: #999999;
}

.card .card-body {
  padding: 0 20px;
  margin-top: 20px;
}

.card .card-media {
  padding: 0 20px;
  margin: 0 -14px;
}

.card .card-media img {
  max-width: 100%;
  max-height: 100%;
}

.card .card-actions {
  min-height: 30px;
  padding: 0 20px 20px 20px;
  margin: 20px 0 0 0;
}

.card .card-comments {
  padding: 20px;
  margin: 0;
  background-color: #f8f8f8;
}

.card .card-comments .comments-collapse-toggle {
  padding: 0;
  margin: 0 20px 12px 20px;
}

.card .card-comments .comments-collapse-toggle a,
.card .card-comments .comments-collapse-toggle span {
  padding-right: 5px;
  overflow: hidden;
  font-size: 12px;
  color: #999;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-comments .media-heading {
  font-size: 13px;
  font-weight: bold;
}

.card.people {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 300px;
  padding-top: 0;
  margin-left: 20px;
  overflow: hidden;
  vertical-align: top;
}

.card.people:first-child {
  margin-left: 0;
}

.card.people .card-top {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 170px;
  height: 150px;
  background-color: #ffffff;
}

.card.people .card-top.green {
  background-color: #53a93f;
}

.card.people .card-top.blue {
  background-color: #427fed;
}

.card.people .card-info {
  position: absolute;
  top: 150px;
  display: inline-block;
  width: 100%;
  height: 101px;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.people .card-info .title {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #404040;
}

.card.people .card-info .desc {
  display: block;
  margin: 8px 14px 0 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.people .card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  line-height: 29px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.receipt-background {
  background: url("/content/images/icone_new/document/Scontrino.png");
}

.invoice-background {
  background: url("/content/images/icone_new/document/Fattura.png");
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: left;
}

.list-group-item .cardheader,
.card.hovercard .cardheader {
  background-size: contain;
  height: 135px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
}

.list-group-item .cardheader,
.card.products .cardheader {
  background-image: url("/content/images/empty-product-placeholder.png");
}

.card.shop .cardheader {
  background-image: url("/content/images/empty-shop-placeholder.png");
}

.card.warranty .cardheader {
  background-image: url("/content/images/icone_new/icone_sfondo_bianco/bilancia_scudo_bianco.png");
}

.list-group-item .cardheader {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: transparent;
}

.list-group-item .cardheader .card.shop {
  text-align: left
}

.list-group-item .body-item {
  margin-left: 60px;
}

.receipt.list-group-item .body-item {
  margin-left: 100px;
}

.receipt.list-group-item .cardheader {
  position: absolute;
  width: 90px;
  background-color: transparent;
}

.list-item-controller {
  border: none;
  margin-bottom: 2em !important;
}

.ge-sidebar .list-group-item {
  padding: 5px 15px;
}

.card.hovercard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .info {
  padding: 4px 8px 10px;
  height: 230px;
  overflow: auto;
}

.card.hovercard .info .title {
  font-size: 15px;
}

.card.hovercard .info .desc {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
  height: 40px;
}

.list-group-item .avatar.badge,
.card .avatar.badge {
  height: 15px;
  border-radius: 50%;
  line-height: 10px;
  width: 15px;
  display: inline-block;
}

/* END CARD CSS */

/* WARRANTY CLAIM CSS */
.warranty-claim-icon {
  background-color: $garanteasy-green;
  height: 70px;
  max-width: 80px;
  width: 100%;
  display: inline-flex;

  i {
    font-size: 50px;
    margin: auto;
    color: white;
  }

  @media (max-width: 1024px) {
    height: 80px;
    max-width: 100px;
    width: 100%;

    i {
      font-size: 55px;
    }
  }

  @media (max-width: 900px) {
    height: 55px;
    max-width: 75px;
    width: 100%;

    i {
      font-size: 45px;
    }
  }
}

.warranty-claim-type {
  img {
    height: 50px;
    display: inline-block;
  }

  input {
    margin-top: 20px;
  }

  span {
    margin-top: 20px;
  }

  [class^="garanteasy-"],
  [class*=" garanteasy-"] {
    font-size: 48px;
  }
}

/* END WARRANTY CLAIM CSS*/

/* START - TOOLTIP WITH DISABLED BUTTON */
.tooltip-wrapper {
  display: inline-block;
  /* display: block works as well */
}

.tooltip-wrapper .btn[disabled] {
  /* don't let button block mouse events from reaching wrapper */
  pointer-events: none;
}

.tooltip-wrapper.disabled {
  /* OPTIONAL pointer-events setting above blocks cursor setting, so set it here */
  cursor: not-allowed;
}

/* END - TOOLTIP WITH DISABLED BUTTON */

/* START FORM TRIANGLE POSITION */
.form-triangle-left {
  position: absolute;
  left: 0;
  right: initial;
  z-index: 100;
}

.left-calendar .form-triangle-left {
  left: 40px;
}

.product-triangle-padding {
  padding-top: 6px;
  left: 5px;
}

.custom-form-group .fa-exclamation-triangle {
  top: 0;
  right: initial;
  left: 0;
}

.custom-form-group .fa-exclamation-triangle+input,
.custom-form-group .fa-exclamation-triangle+div>div>.ui-select-toggle {
  padding-left: 30px;
}

.custom-form-group span.btn.btn-default.form-control.ui-select-toggle,
.custom-form-group span.btn.btn-default.ui-select-toggle.form-control-modified {
  background: transparent;
}

.form-group .triangle {
  position: relative;
}

.form-group .fa-exclamation-triangle {
  top: 0;
  right: initial;
  left: 0;
  position: absolute;
}

.form-group .fa-exclamation-triangle+input,
.form-group .fa-exclamation-triangle+div>div>.ui-select-toggle {
  padding-left: 30px;
}

.form-group span.btn.btn-default.form-control.ui-select-toggle,
.form-group span.btn.btn-default.ui-select-toggle.form-control-modified {
  background: transparent;
}

/* END FORM TRIANGLE POSITION */

.align-top {
  vertical-align: top
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  color: $garanteasy-text-list-group-active;
  background-color: $garanteasy-green-background;
  border-color: $garanteasy-green-background;
}

.ge-sidebar {
  .fas {
    color: $garanteasy-green;
    min-width: 30px;
    text-align: center;
  }

  .fas.garanteasy-scontrinobucato {
    font-size: 32px;
    //background-color: $garanteasy-green;
    //color: $body-bg;
  }
}

.navbar-fixed-bottom a.active {
  color: $navbar-fixed-bottom-active-color;
}

@media (min-width: 768px) {

  .navbar>.container .navbar-brand,
  .navbar>.container-fluid .navbar-brand {
    margin-left: 14px;
  }
}

.tutorial h5 .fas {
  width: 30px;
}

#not-collapse .navbar-nav.navbar-right:last-child {
  margin-right: 0 !important;
}

.check-box-align-middle {
  line-height: 21px;
}

input[type=checkbox].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type=checkbox].css-checkbox+span.css-label {
  cursor: pointer;
}

.css-checkbox-container>* {
  color: #a29b9b;
}

.css-checkbox-container input[type=checkbox].ng-not-empty+span {
  color: $garanteasy-green;
}

.css-checkbox-container>.check>span:before {
  content: "\f00c";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-right: 5px;
}

// input[type=checkbox].ng-not-empty + span.css-label:before, input[type=checkbox].ng-not-empty + .fas:before {
//   color: #16a74a;
// }
span.css-label:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: large;
  margin-right: 4px;
}

span.css-label.document:before {
  content: "\f571";
}

span.css-label.shop:before {
  content: "\f07a";
}

span.css-label.product:before {
  content: "\f02a";
}

span.css-label.warrenty:before {
  content: "\f3ed";
}

.css-checkbox-container {
  padding-left: 10px;
  line-height: 30px;
  position: relative;
  display: inline-block;
}

/* START STEP WIZARD FORM */
.stepwizard-step p {
  margin-top: 0px;
  color: #666;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  /*opacity: 1 !important;
  filter: alpha(opacity=100) !important;*/
}

.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  color: #bbb;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
    background-color: white;
  }

  .btn-success {
    background-color: $garanteasy-green;
    color: white;
  }

  .btn-default {
    color: $garanteasy-black;
    border: 1px solid #ccc !important;
  }
}

.padding-step {
  padding: 0 2rem;
}

.stepwizard-step-container {
  min-height: 50vh;
}

.stepwizard-back {
  background-color: $garanteasy-gray;
}

.stepwizard-product {
  min-height: 12vh;
  padding-right: 2rem;
}

.stepwizard-width {
  width: 14.285714286%;
}

/* END STEP WIZARD FORM */

.table>thead>tr>th {
  white-space: nowrap;
}

.receipt-image-build-preview {
  overflow: hidden;
  height: 133px;
  margin: auto;
  font-size: 6px;
  color: black;
  display: block;
}

.receipt-image-build-panel {
  padding: 0 4px;
  border-radius: 0;
  font-family: 'Fira Sans Extra Condensed', sans-serif !important;

  .logo-container {
    max-height: 150px;

    img {
      height: 133px;
    }

    h1 {
      margin-top: 50px
    }
  }

  .margin-top-15 {
    margin-top: 15px;
  }
}

.uib-daypicker .btn-default {
  color: #333;
  background-color: #fff;
}

.uib-daypicker .btn-default.active {
  color: #333;
  background-color: #a4a7a5;
}

.vertically-aligned {
  display: flex;
  align-items: center;
}

.vertically-aligned *+span {
  margin-left: 5px;
}

.image-build-wrapper {
  position: relative;
}

.image-build-buttons {
  margin: 1px;
}

.text-muted {
  font-weight: bold;
}


.product-details-wrapper {
  padding: 1em;
}

.nav-tabs>li.active>a {
  border-bottom: 4px solid transparent;
}

.space-between {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.inline-flex {
  display: inline-flex;
}

.shop-save-padding {
  padding-bottom: 3%;
}


@media (min-width: 991) {
  .align-registration {
    margin-left: 15% !important;
  }
}

.faq-box {
  padding: 1em 1em 1em 1em;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: $garanteasy-green;
  border-radius: 0px 0px 0px 0px;
  color: #7a7a7a;
  margin-top: 1%;
  margin-bottom: 2%;
}

.row.text-center>div {
  display: inline-block;
  float: none;
}

.icon-margin {
  margin-right: 2%;
}

@media (max-width: 991px) {
  .reset-position {
    text-align: center;

  }

  .button-reset-position {
    margin-top: 8%;
  }

  .title-reset {
    font-size: 19px;
  }
}

.covid-logo {
  width: 170px;
  margin-top: -15px;
  position: absolute;
  margin-left: 200px;
  height: 60px !important;
}

i {
  font-style: normal;
}

.mobile-textarea {
  height: 90px;
  width: 300px;
}

.add-new-product-mobile {
  font-size: 2rem;
  font-weight: 600;
  background-color: $garanteasy-green;
  width: 100vw;
  margin-left: -15px;
  color: #fff;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
}

#receiptPrintContent {
  .price {
    text-align: right;
  }
}

a.carousel-control:hover {
  color: $garanteasy-gray;
  background-image: none;
}

a.carousel-control {
  color: $garanteasy-green;
}

a.carousel-control.left {
  color: $garanteasy-green;
  background-image: none;
}

a.carousel-control.right {
  background-image: none;
}

ol.carousel-indicators {
  background-color: rgba(255, 255, 255, 0.8);

  li {
    border-color: $garanteasy-green;
  }

  li.active {
    background-color: $garanteasy-green;
  }
}

a {
  color: $garanteasy-green-text;
}

.right-green-border {
  border-right: 1px solid $garanteasy-green;
}

.component-icona {
  text-align: center;
}

.hovercard .garanteasy-icon,
.thumbnail-container .garanteasy-icon,
.receipt-details-wrapper .garanteasy-icon,
.component-icona>.garanteasy-icon {
  font-size: 7em;
}

.garanteasy-icon {
  text-align: center;
}

.dashboard .garanteasy-icon {
  font-size: 4em;
}

.component-dettaglio .garanteasy-icon,
.component-dettaglio>.garanteasy-icon {
  font-size: 1.5em;
}

.title-page-mobile .garanteasy-icon {
  color: white;
  font-size: 3em;
}

.img-responsive {
  margin-left: auto;
  margin-right: auto;
}
